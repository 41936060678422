import React, { useEffect } from 'react';
import { Zicon } from '@zoneatlas/zoneatlas-icons';
import ReactMarkdown from 'react-markdown';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import { useCtx } from '../Context';
import './card.scss';
import { useTranslation } from '../Context/Translations';
import { Media, Category } from '../../types';
import Button from '../Button/index';
import Tags from '../Tags';

type CarouselProps = {
  images: Media[];
};

const Carousel = ({ images }: CarouselProps): JSX.Element => {
  return (
    <div className="image-carousel">
      <AwesomeSlider
        buttons={images.length > 1 ? true : false}
        organicArrows={false}
        fillParent={true}
        buttonContentLeft={
          <Zicon icon="ui/chevron/big-left" customClass="card-chevron" />
        }
        buttonContentRight={
          <Zicon icon="ui/chevron/big-right" customClass="card-chevron" />
        }
      >
        {images.map((image: Media, i: number) => (
          <div
            key={i}
            // className="card__image"
            // alt="The location"
            data-src={`${process.env.REACT_APP_UPLOADS_URL}/${process.env.REACT_APP_ZONE_ID}/${image.path}`}
          />
        ))}
      </AwesomeSlider>
    </div>
  );
};

type ContentProps = {
  onClose: () => void;
};

const Content = ({ onClose }: ContentProps): JSX.Element | null => {
  const { selectedMarker, categories, colours } = useCtx();
  const { translate } = useTranslation();
  const images: Media[] = [];
  const otherFiles: any = [];
  if (selectedMarker) {
    if (selectedMarker.Media) {
      selectedMarker.Media.map((m: Media) => {
        if (m.type.includes('image')) {
          images.push(m);
        } else {
          otherFiles.push(m);
        }
        return null;
      });
    }
    const withPic = images.length > 0;
    let cat = null;
    if (selectedMarker.Categories.length !== 0) {
      cat = categories?.find(
        (c: Category) => c.id === selectedMarker.Categories[0].id
      );
    }

    return (
      <div className="card__content">
        <button type="button" className="close-btn" onClick={onClose}>
          ×
        </button>
        <div className="card__header">
          {selectedMarker.Categories[0] ? (
            <Zicon
              icon={cat ? cat.icon : ''}
              customClass={`cat-icon ${withPic ? 'cat-icon--with-image' : ''}`}
            />
          ) : null}
          {withPic ? <Carousel images={images} /> : null}
        </div>
        <div
          style={{ backgroundColor: colours.bg, color: colours.text }}
          className="card__content-wrapper"
        >
          <h2 className="card__title">{selectedMarker.title}</h2>
          <div className="card__tags">
            {selectedMarker.Tags.map(tag => (
              <Tags {...tag} key={tag.id} />
            ))}
          </div>
          <div className="card__description">
            <ReactMarkdown linkTarget="_blank">
              {selectedMarker?.content ? selectedMarker?.content : ''}
            </ReactMarkdown>
            {otherFiles.length !== 0
              ? translate('attachment').toUpperCase()
              : null}
            {otherFiles.map((d: any, i: number) => {
              const res = d.path.split('/');
              return (
                <a
                  key={`attachment-${i}`}
                  className="card__attachment"
                  href={`${process.env.REACT_APP_UPLOADS_URL}/${process.env.REACT_APP_ZONE_ID}/${d.path}`}
                  download
                >
                  {res[res.length - 1]}
                </a>
              );
            })}
          </div>
          <div className="card__buttons">
            {selectedMarker.buttons
              ? selectedMarker.buttons.map(btn => (
                  <Button {...btn} key={btn.id} />
                ))
              : null}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const Card = ({ children }: { children?: JSX.Element }): JSX.Element | null => {
  const { selectedMarker, selectedSubzone, map, selectMarker } = useCtx();
  const cardContainerRef = React.useRef<HTMLDivElement>(null);

  const cardOnClose = (): void => {
    if (map && map.current && map.current.container && selectedSubzone) {
      map.current.container.classList.remove('up');
      map.current.leafletElement.invalidateSize();
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, '', `/${selectedSubzone.slug}`);
      selectMarker(null);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        cardContainerRef.current &&
        cardContainerRef.current === event.target
      ) {
        cardOnClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    document.addEventListener('fullscreenchange', event => {
      if (document.fullscreenElement) {
        if (selectedMarker !== null) {
          cardOnClose();
        }
      } else {
        if (selectedMarker !== null) {
          cardOnClose();
        }
      }
    });
  });

  // Resize the map to the upper portion of the view when card is open
  if (!children && selectedMarker === null) {
    if (map && map.current && map.current.container) {
      map.current.container.classList.remove('up');
      map.current.leafletElement.invalidateSize();
    }
    return null;
  }

  if (map && map.current && map.current.container) {
    map.current.container.classList.add('up');
  }

  return (
    <div ref={cardContainerRef} className="card">
      {children ? children : <Content onClose={cardOnClose} />}
    </div>
  );
};

export default Card;

import React, { useState, useEffect } from 'react';
import { Polygon, Popup } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import { slugify } from '../../../utils';
import { useCtx } from '../../Context';
import { useTranslation } from '../../Context/Translations';
import { SubzoneType, TagType } from '../../../types';
import './Subzones.scss';


const Subzone = ({
  geo,
  title,
  subtitle,
  webaddress,
  slug,
  description,
  Media,
  Tags,
  id
}: SubzoneType): JSX.Element | null => {
  const { translate } = useTranslation();
  const history = useHistory();
  const [activityTags, setActivityTags] = useState<TagType[]>([]);
  const { embed } = useCtx();

  useEffect(() => {
    const tempTags: TagType[] = [];
    for (let i = 0; i < Tags.length; i++) {
      const splitted = Tags[i].title.split('_');
      if (splitted.length === 2) {
        if (splitted[0] === 'activity') {
          // To prevent modifying actual tags
          // Initial tempTag
          let tempTag: TagType = { Subzones: [], id: 0, title: '' };
          tempTag = Object.assign(tempTag, Tags[i]);
          tempTag.title = splitted[1];
          tempTags.push(tempTag);
        }
      }
    }
    setActivityTags(tempTags);
  }, [Tags]);

  if (!geo.coordinates) {
    return null;
  }

  const coords: L.LatLng[] = [];
  L.geoJSON(geo, {
    coordsToLatLng: c => {
      let ll;
      if (c[0] < c[1]) {
        ll = new L.LatLng(c[1], c[0], c[2]);
        coords.push(new L.LatLng(c[1], c[0], c[2]));
      } else {
        ll = new L.LatLng(c[0], c[1], c[2]);
        coords.push(new L.LatLng(c[0], c[1], c[2]));
      }
      return ll;
    }
  });

  let withPic = false;
  if (Media && Media.length !== 0) {
    withPic = true;
  }

  const buttonClick = (): void => {
    history.push(`/${slug}`);
  };

  // Fit popup to mobile devices, but add padding on larger screens
  /*const h = window.innerHeight;
    let padding: [number, number] = [30, 90];
    if (h <= 690) {
      padding = [0, 0];
    } else if (h > 690 && h <= 700) {
      padding = [0, 10];
    } else if (h > 700 && h <= 750) {
      padding = [20, 70];
    }*/
  return (
    <Polygon
      id={`mapobject-${id}`}
      positions={coords}
      color='#00B4A9'
    >
      <Popup
        autoPan={true}
        offset={[0, -35]}
        autoPanPadding={[0, 0]}
        className="subzone-popup"
      >
        <div className="subzone-popup__header">
          {withPic && Media ? (
            <img
              className="subzone-popup__image"
              alt={title}
              src={`${process.env.REACT_APP_UPLOADS_URL}/${process.env.REACT_APP_ZONE_ID}/${Media[0].path}`}
            ></img>
          ) : null}

          <div className="subzone-popup__header-content">
            <h2 className="subzone-popup__title">{title}</h2>
            <h3 className="subzone-popup__area">{subtitle || ''}</h3>
            <div className="subzone-popup__tag-container">
              {activityTags.map((d: TagType, i: number) => {
                return (
                  <div
                    className={`category_icon category_icon--${slugify(
                      d.title
                    )}`}
                    key={`${i}tag-icon`}
                  >
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="subzone-popup__description">
          <ReactMarkdown linkTarget="_blank">{description || ''}</ReactMarkdown>
        </div>
        {webaddress && embed ? (
          <a
            target="_top"
            rel="noopener noreferrer"
            className="subzone-popup__button"
            href={webaddress}
          >
            {translate('subzonepopup-button-text')}
          </a>
        ) : (
          <button className="subzone-popup__button" onClick={buttonClick}>
            {translate('subzonepopup-button-text')}
          </button>
        )}
      </Popup>
    </Polygon>
  );
};

const Subzones = (): JSX.Element | null => {
  const { filteredSubzones } = useCtx();

  if (!filteredSubzones.length) {
    return null;
  }

  return (
    <>
      {filteredSubzones.map((s: SubzoneType) => (
        <Subzone key={s.id} {...s} />
      ))}
    </>
  );
};

export default Subzones;

import React, { useEffect, useState } from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.pattern';
import { useCtx } from '../../Context';
import { MapItem, MapRefType, Category } from '../../../types';
import './Areas.scss';
import { isMobile } from '../../../utils';

type clickProps = {
  selectMarker: (mark: MapItem) => void;
  marker: MapItem;
  map: MapRefType | null;
  bounds: L.LatLngBounds;
};

const handleClick = ({
  selectMarker,
  marker,
  map,
  bounds
}: clickProps): void => {
  selectMarker(marker);
  if (map && map.current) {
    const mapElem = map.current.leafletElement;
    let padding: [number, number] = [0, 0];

    if (isMobile) {
      // 'card' takes 60% of window height -> project coordinates to 40% window height.
      padding = [0, window.innerHeight * 0.4];
    }
    mapElem.fitBounds(bounds, { maxZoom: 18, paddingBottomRight: padding });
  }
};

const Areas = (): JSX.Element => {
  const {
    areas,
    selectMarker,
    selectedMarker,
    map,
    filteredCategories,
    selectedSubzone
  } = useCtx();
  const [stripe, setStripe] = useState<L.StripePattern | null>(null);

  useEffect(() => {
    // Add here actual check if we want the stripes in area
    // eslint-disable-next-line no-constant-condition
    if (false) {
      const stripes = new L.StripePattern({ color: '#1ea038' });
      //stripes.addTo(map.current.leafletElement);
      setStripe(stripes);
    }
  }, [map]);

  return (
    <>
      {areas.map((d: MapItem, i: number) => {
        if (d.type !== 'Area') {
          return null;
        }
        let cat = '';
        let catId: number | null = null;
        let hasCat = false;
        if (d.Categories.length !== 0) {
          cat = d.Categories[0].slug;
          catId = d.Categories[0].id;
          hasCat = true;
        }

        let opacity = 0.4;
        let fillOpacity = 0.25;
        let dashArray = '0';
        if (d.styleProperties?.lineStyle === 'dotted') {
          dashArray = '1,10';
        } else if (d.styleProperties?.lineStyle === 'dashed') {
          dashArray = '10,10';
        }
        if (d.styleProperties?.fillOpacity === 0) {
          fillOpacity = 0;
        } else if (d.styleProperties?.fillOpacity === 0.25) {
          fillOpacity = 0.25;
        } else if (d.styleProperties?.fillOpacity === 0.5) {
          fillOpacity = 0.5;
        } else if (d.styleProperties?.fillOpacity === 0.75) {
          fillOpacity = 0.75;
        } else if (d.styleProperties?.fillOpacity === 1) {
          fillOpacity = 1;
        }

        let color = '';
        const category = filteredCategories.find(
          (c: Category) => c.id === catId
        );
        // Has category & the category is in the filter
        if (hasCat && category) {
          color = category.color;
          // Has category, but is filtered out
        } else if (hasCat && !category) {
          return null;
          // Doesn't have category and is filtered out
        } else if (!category && d.Categories.length !== 0) {
          return null;
        }

        let selected = false;
        if (selectedMarker) {
          selected = selectedMarker.id === d.id;
        }

        return (
          <Polygon
            key={`area-${i}`}
            positions={d.geo.coordinates as any}
            // Add area--striped to class, if we need stripes
            className={`area area--${cat} area-${d.id} ${
              d.isInteractive ? '' : 'area--non-clickable'
            }`}
            weight={2}
            color={color ? color : '#1ea038'}
            dashArray={dashArray}
            opacity={opacity}
            fillOpacity={fillOpacity}
            interactive={d.isInteractive}
            renderer={!d.isInteractive ? L.canvas() : L.svg()}
            onClick={(e: any): void => {
              if (selectedSubzone) {
                handleClick({
                  selectMarker: selectMarker,
                  marker: d,
                  map: map,
                  bounds: e.target.getBounds()
                });
                // eslint-disable-next-line no-restricted-globals
                history.pushState(
                  null,
                  '',
                  `/${selectedSubzone.slug}/${d.slug}/#/${
                    e.target.getBounds().getCenter().lat
                  }/${e.target.getBounds().getCenter().lng}`
                );
              }
            }}
            fillPattern={stripe}
          >
            {selected ? null : d.isInteractive ? (
              <Tooltip direction="top" className={cat}>
                {d.title}
              </Tooltip>
            ) : null}
          </Polygon>
        );
      })}
    </>
  );
};

export default Areas;

import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import { Zicon } from '@zoneatlas/zoneatlas-icons';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom';

import { useCtx } from '../../Context';
import { MapItem, MapRefType, Coordinate, Category } from '../../../types';
import { isMobile } from '../../../utils';

import './Markers.scss';

type iconProps = {
  category: string;
  selected: boolean;
  zicon: string;
  color: string;
  id: number;
};

const markerIcon = ({
  category,
  selected,
  zicon,
  color,
  id
}: iconProps): L.DivIcon => {
  const iconWidth = 50;
  const iconHeight = 50;
  const iconSize = [iconWidth, iconHeight];
  const iconAnchor = [iconWidth / 2, iconHeight];
  let icon = '';
  if (color === '' || !color) {
    color = '#153657';
  }

  icon = ReactDOMServer.renderToString(
    <>
      <Zicon icon="ui/location-blank" color={color} customClass="icon" />
      {selected ? (
        <div
          className="selected-circle"
          style={{ border: `2px solid ${color}` }}
        />
      ) : null}
      <Zicon icon={zicon} color="#fff" customClass="bg" />
    </>
  );
  const options: object = {
    className: `marker-icon marker-icon--${category} marker-icon--marker-${id} ${
      selected ? 'selected' : ''
    }`,
    html: `${icon}`,
    iconSize,
    iconAnchor
  };
  return L.divIcon(options);
};

type clickProps = {
  selectMarker: (mark: MapItem) => void;
  marker: MapItem;
  map: MapRefType | null;
};

const handleClick = ({ selectMarker, marker, map }: clickProps): void => {
  selectMarker(marker);
  if (map && map.current) {
    const mapElem = map.current.leafletElement;
    const currentZoom = mapElem.getZoom();
    const zoom = currentZoom <= 18 ? 18 : currentZoom;

    const px = mapElem.project(marker.geo.coordinates as Coordinate, zoom);

    if (isMobile) {
      // 'card' takes 60% of window height -> project coordinates to 40% window height.
      px.y += window.innerHeight * 0.3;
    }
    mapElem.setView(mapElem.unproject(px, zoom), zoom);
  }
};

const Markers = (): JSX.Element => {
  const history = useHistory();
  const {
    markers,
    selectMarker,
    selectedMarker,
    selectedSubzone,
    filteredCategories,
    map,
    center,
    zoomLevel
  } = useCtx();

  return (
    <>
      {markers.map((d: MapItem, i: number) => {
        if (d.type !== 'Point') {
          return null;
        }
        let cat = '';
        let catId: number | null = null;
        if (d.Categories.length !== 0) {
          cat = d.Categories[0].slug;
          catId = d.Categories[0].id;
        }

        let icon = '';
        let color = '';
        const category = filteredCategories.find(
          (c: Category) => c.id === catId
        );
        if (category) {
          icon = category.icon;
          color = category.color;
        } else {
          return null;
        }

        let selected = false;
        if (selectedMarker) {
          selected = selectedMarker.id === d.id;
        }

        let lat = d.geo.coordinates[0];
        let lng = d.geo.coordinates[1];
        if (center.length > 0) {
          lat = center[0];
          lng = center[1];
        }

        return (
          <Marker
            key={`marker-${i}`}
            position={d.geo.coordinates as Coordinate}
            interactive={d.isInteractive}
            icon={markerIcon({
              category: cat,
              selected: selected,
              zicon: icon,
              color: color,
              id: d.id
            })}
            onClick={(): void => {
              handleClick({
                selectMarker,
                marker: d,
                map: map
              });
              if (selectedSubzone) {
                history.push(
                  `/${selectedSubzone.slug}/${d.slug}/#/${zoomLevel}/${lat}/${lng}`
                );
              }
            }}
          >
            {selected ? null : (
              <Tooltip offset={[0, -10]} direction="top" className="tooltip">
                <div
                  style={{ borderColor: color, backgroundColor: color }}
                  className="tooltip__content"
                >
                  {d.title}
                </div>
              </Tooltip>
            )}
          </Marker>
        );
      })}
    </>
  );
};

export default Markers;

import React from 'react';
import { useCtx } from '../Context';
import { Zicon } from '@zoneatlas/zoneatlas-icons';

type fullScreenProps = {
  handle: any;
};

const FullscreenButton = ({ handle }: fullScreenProps): JSX.Element => {
  const { colours } = useCtx();

  return (
    <button
      onClick={handle.active ? handle.exit : handle.enter}
      className={`fullscreen-btn`}
    >
      <Zicon
        icon={handle.active ? 'ui/fullscreen-active' : 'ui/fullscreen'}
        color={colours.text ? '#ffffff' : colours.text}
        customClass={`fullscreen-btn__icon`}
      />
    </button>
  );
};

export default FullscreenButton;

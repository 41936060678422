import React, { useState } from 'react';
import { Zicon } from '@zoneatlas/zoneatlas-icons';
import { centroid } from '@turf/turf';

import { useCtx } from '../../Context/';
import './searchBar.scss';
import { isMobile } from '../../../utils';
import { MapItem, SubzoneType } from '../../../types';

type ResultsProps = {
  results: Array<MapItem | SubzoneType>;
  onItemClick: (item: MapItem | SubzoneType) => void;
};

// Search result in list
const SearchResults = ({
  results,
  onItemClick
}: ResultsProps): JSX.Element | null => {
  const {
    selectedSubzone,
    selectMarker,
    map,
    selectedMarker,
    colours
  } = useCtx();
  const clickFunc = (item: MapItem | SubzoneType): void => {
    onItemClick(item);

    if (selectedSubzone !== null) {
      selectMarker(item);
    }

    if (map && map.current) {
      const mapElem = map.current.leafletElement;
      let layerId = null;
      const layers: L.Layer[] = [];
      mapElem.eachLayer((layer: L.Layer) => {
        layers.push(layer);
      });

      layerId = Object.keys(layers).find(
        (key: string) => layers[key].options.id === `mapobject-${item.id}`
      );

      if (typeof layerId === 'undefined' || layerId === null) {
        // Layer not found because it's out of mapbounds
        // fly to target and refind layer
        let coordinates: [number, number] = [0, 0];

        if (item.geo.type === 'Polygon' || item.geo.type === 'LineString') {
          const coordinates2 =
            centroid(item.geo).geometry?.coordinates || coordinates;

          if (coordinates2[0] < coordinates2[1]) {
            coordinates = [coordinates2[1], coordinates2[0]];
          } else {
            coordinates = [coordinates2[0], coordinates2[1]];
          }
        } else if (item.geo.type === 'Point') {
          coordinates = item.geo.coordinates;

          // Coordinate hassle for multipolygon & multiline?
        } /*else if (item.geo.type === 'MultiPolygon') {
        }*/
        const currentZoom = mapElem.getZoom();
        mapElem.setView(coordinates, currentZoom <= 18 ? 18 : currentZoom);

        layerId = Object.keys(layers).find(
          (key: string) => layers[key].options.id === `mapobject-${item.id}`
        );
      }

      if (
        layerId !== undefined &&
        layerId !== null &&
        (selectedMarker === null || item.id !== selectedMarker.id)
      ) {
        const layer = layers[layerId];
        layer.fireEvent('click');

        if (layer._bounds) {
          mapElem.fitBounds(layer._bounds);
        }
      }
    }
  };

  if (results.length === 0) {
    return null;
  }

  return (
    <div
      style={{ backgroundColor: colours.bg }}
      className="results-container"
      id="results-container"
    >
      {results.map((d: MapItem | SubzoneType, i: number) => {
        return (
          <button
            style={{ backgroundColor: colours.bg, color: colours.text }}
            className="btn"
            onClick={(): void => clickFunc(d)}
            key={`${i}result`}
          >
            <p>{d.title}</p>
            <Zicon icon="ui/chevron/small-right" customClass="result-chevron" />
          </button>
        );
      })}
    </div>
  );
};

// Searchbar component
type SearchProps = {
  search: (val: string) => Array<MapItem | SubzoneType> | [];
  toggleMenu: (val: boolean) => void;
  placeholderText: string;
  classes: string;
};

const SearchBar = ({
  search,
  toggleMenu,
  placeholderText,
  classes
}: SearchProps): JSX.Element => {
  const [results, setResults] = useState<Array<MapItem | SubzoneType> | []>([]);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const { colours } = useCtx();

  const doSearch = (value: string): void => {
    const a: Array<MapItem | SubzoneType> | [] = search(value);
    setResults(a);
  };

  const onItemClick = (item: MapItem | SubzoneType): void => {
    // Close main menu if on mobile
    if (isMobile) {
      toggleMenu(false);
    }

    // Set input value
    if (searchInputRef.current) {
      searchInputRef.current.value = item.title;
    }

    setResults([]);
  };

  return (
    <div className={`search-container ${classes}`}>
      <input
        style={{ backgroundColor: colours.bg, color: colours.text }}
        ref={searchInputRef}
        type="text"
        className="search"
        id="search"
        placeholder={placeholderText}
        onChange={(e): void => doSearch(e.target.value)}
        onClick={(e: React.MouseEvent): void =>
          doSearch((e.target as HTMLInputElement).value)
        }
      ></input>
      <Zicon color={colours.text} icon="ui/search" customClass="search-icon" />
      <SearchResults results={results} onItemClick={onItemClick} />
    </div>
  );
};

export default SearchBar;

import React from 'react';

import './message.scss';

type MessageProps = {
  message: string | null;
  isError: boolean;
};

const Message = ({ message, isError }: MessageProps): JSX.Element | null => {
  return (
    <div
      className={`message ${isError ? 'error' : ''} ${
        message !== null ? 'open' : 'closed'
      }`}
    >
      {message}
    </div>
  );
};

export default Message;

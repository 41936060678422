import React from 'react';
import { Zicon } from '@zoneatlas/zoneatlas-icons';
import { useCtx } from '../../Context';

import './menuButton.scss';

// Burger menu button component
type ButtonProps = {
  menuOpen: boolean;
  open: (val: boolean) => void;
  classes: string;
};

const MenuButton = ({ menuOpen, open, classes }: ButtonProps): JSX.Element => {
  const { colours } = useCtx();
  return (
    <div
      style={{ backgroundColor: colours.bg }}
      className={`toggle-menu ${
        menuOpen ? 'toggle-menu--open' : ''
      } ${classes}`}
      onClick={(): void => open(!menuOpen)}
    >
      <Zicon
        icon={
          menuOpen
            ? 'ui/remove'
            : classes === 'main-page'
            ? 'category/info-board'
            : 'ui/settings-active'
        }
        color="#fafafa"
        customClass={`toggle-menu__icon ${
          menuOpen ? 'toggle-menu__icon--open' : ''
        }`}
      />
    </div>
  );
};

export default MenuButton;

import React from 'react';
import './button.scss';

type ButtonProps = {
  id: Number;
  title: string;
  url: string;
  color: string;
};

const Button = (props: ButtonProps) => {
  const urlPrefixer = (props: any) => {
    if (props.url.startsWith('http')) {
      return props.url;
    }
    return `http://${props.url}`;
  };

  return (
    <a
      href={urlPrefixer(props)}
      target="_blank"
      rel="noopener noreferrer"
      style={{ backgroundColor: props.color }}
      className="button-styled"
    >
      {props.title}
    </a>
  );
};

export default Button;

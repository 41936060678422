import React, { useEffect, useState } from 'react';
import { Zicon } from '@zoneatlas/zoneatlas-icons';
import { centroid } from '@turf/turf';

import { useCtx } from '../Context/';
import { SubzoneType, Category, MapRefType } from '../../types';

type CheckMarkType = {
  category: Category;
  handleMainClick: (category: Category) => void;
  catFilters: any[];
  icon: string;
};

const CheckMark = ({
  category,
  handleMainClick,
  catFilters,
  icon
}: CheckMarkType): JSX.Element => {
  const { colours, categories } = useCtx();
  if (
    catFilters.includes(category) &&
    catFilters.filter((filter: any) => filter.parentId === category.id)
      .length === categories.filter(c => c.parentId === category.id).length
  ) {
    return (
      <div
        onClick={() => handleMainClick(category)}
        style={{ border: `1px solid ${colours.support}` }}
        className="circle-checkbox"
      ></div>
    );
  } else if (
    catFilters &&
    catFilters.includes(category) &&
    catFilters.find((filter: any) => filter.parentId === category.id) === -1
  ) {
    return (
      <div
        onClick={() => handleMainClick(category)}
        style={{ border: `1px solid ${colours.support}` }}
        className="circle-checkbox"
      ></div>
    );
  } else if (
    catFilters.includes(category) &&
    catFilters.find((cat: any) => cat.parentId === category.id) !== -1
  ) {
    return (
      <div
        onClick={() => handleMainClick(category)}
        style={{ backgroundColor: colours.support }}
        className="circle-checkbox"
      >
        <Zicon
          customClass="circle-icon"
          color={colours.text ? colours.text : '#f6f6f6'}
          icon={icon}
        />
      </div>
    );
  } else {
    return (
      <div
        onClick={() => handleMainClick(category)}
        className="circle-checkbox"
        style={{ backgroundColor: colours.support }}
      >
        <Zicon
          customClass="circle-icon"
          color={colours.text ? colours.text : '#f6f6f6'}
          icon={icon}
        />
      </div>
    );
  }
};

type CategoryItemType = {
  category: Category;
  onClickSub?: (cat: Category) => void;
  onClickMain?: (cat: Category) => void;
  subCats?: Category[];
  catFilters: Category[];
  setCatFilter?: React.Dispatch<any>;
};

// For subzone page
const CategoryItem = ({
  category,
  onClickSub,
  onClickMain,
  subCats,
  catFilters,
  setCatFilter
}: CategoryItemType): JSX.Element => {
  const [displaySubs, setDisplaySubs] = useState<number[]>([]);
  const [icon, setIcon] = useState<string>('ui/checkmark');
  const { allCats, colours, categories } = useCtx();

  // adds to an active array on clicked
  const handleSubClick = (cat: Category): void => {
    if (onClickSub) {
      onClickSub(cat);
    }
  };

  const handleMainClick = (cat: Category): void => {
    if (onClickMain) {
      onClickMain(cat);
    }
  };

  const existsOnMap = (id: number) => {
    const included = categories.filter(s => s.id === id);
    return included.length > 0;
  };

  const isIncluded = (id: number) => {
    const included = displaySubs.filter(s => s === id);
    return included.length > 0;
  };

  const isParent = (id: number) => {
    const parent = allCats.filter(
      (i: { parentId: number }) => i.parentId === id
    );
    if (parent.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const toggleIncluded = (id: number) => {
    const included = displaySubs.filter(s => s === id);
    if (included.length > 0) {
      setDisplaySubs(displaySubs.filter(s => s !== id));
    } else {
      setDisplaySubs([id, ...displaySubs]);
    }
  };

  useEffect(() => {
    if (catFilters && setCatFilter && onClickMain) {
      const catsOwnChildren = categories.filter(
        c => c.parentId === category.id
      );
      const activelyFiltered = catFilters.filter(
        c => c.parentId === category.id
      );

      if (
        activelyFiltered.length > 0 &&
        activelyFiltered.length <= catsOwnChildren.length
      ) {
        setIcon('ui/minus');
      } else if (activelyFiltered.length === 0) {
        setIcon('ui/checkmark');
      }
    }
  }, [catFilters]);

  let color = '#015168';
  if (category.color) {
    color = category.color;
  }
  return (
    <React.Fragment key={category.id}>
      <div className={`category-container`}>
        {/* <div
          onClick={() => handleMainClick(category)}
          style={
            catFilters && catFilters.includes(category)
              ? { border: `1px solid ${colours.support}` }
              : { backgroundColor: colours.support }
          }
          className="circle-checkbox"
        >
          {catFilters &&
          catFilters.includes(category) &&
          catFilters.includes(
            allCats.find((filter: any) => filter.parentId === category.id)
          ) ? (
            <Zicon
              customClass="circle-icon"
              color={colours.text ? colours.text : '#f6f6f6'}
              icon={icon}
            />
          ) : (
            <Zicon
              customClass="circle-icon"
              color={colours.text ? colours.text : '#f6f6f6'}
              icon={icon}
            />
          )}
        </div> */}

        <CheckMark
          category={category}
          handleMainClick={handleMainClick}
          catFilters={catFilters}
          icon={icon}
        />

        <div
          className={`category btn filter ${
            catFilters && catFilters.includes(category) ? '' : 'active'
          }`}
          onClick={() => toggleIncluded(category.id)}
        >
          {category.icon ? (
            <Zicon
              icon={category.icon}
              customClass="category__icon"
              color={color}
            />
          ) : (
            <div className="category__icon"></div>
          )}
          {category.title}
        </div>
        <div
          className="category__right-zicon"
          onClick={() => toggleIncluded(category.id)}
        >
          {isParent(category.id) ? (
            <Zicon
              icon="ui/triangle/small-right"
              color={colours.text ? colours.text : '#f6f6f6'}
              customClass={
                isIncluded(category.id)
                  ? 'zicon-animated-down'
                  : 'zicon-animated-right'
              }
            />
          ) : null}
        </div>
      </div>
      {allCats && isIncluded(category.id) && category
        ? allCats.map(
            (c: Category) =>
              c.parentId === category.id &&
              existsOnMap(c.id) && (
                <div key={c.id} className="category-container subCategory">
                  <div
                    onClick={() => handleSubClick(c)}
                    style={
                      catFilters && catFilters.includes(c)
                        ? { border: `1px solid ${colours.support}` }
                        : { backgroundColor: colours.support }
                    }
                    className={`circle-checkbox`}
                  >
                    {catFilters && catFilters.includes(c) ? (
                      ''
                    ) : (
                      <Zicon
                        customClass="circle-icon"
                        color={colours.text ? colours.text : '#f6f6f6'}
                        icon="ui/checkmark"
                      />
                    )}
                  </div>
                  <div
                    key={c.id}
                    className={`category btn filter`}
                    onClick={() => handleSubClick(c)}
                  >
                    {c.icon ? (
                      <Zicon
                        icon={c.icon}
                        customClass="category__icon"
                        color={c?.color || color}
                      />
                    ) : (
                      <div className="category__icon"></div>
                    )}
                    {c.title}
                  </div>
                </div>
              )
          )
        : null}
    </React.Fragment>
  );
};

const Content = (): JSX.Element => {
  const {
    setFilteredCategories,
    filteredCategories,
    allCats,
    selectedSubzone,
    subzones,
    map,
    colours,
    categories
  } = useCtx();
  const [catFilters, setCatFilter] = useState<Category[]>([]);
  const [hideFilters, setHideFilter] = useState<Category[]>([]);
  const [masterSwitch, setMasterSwitch] = useState<number>(0);

  useEffect(() => {
    setFilteredCategories(allCats);
    setHideFilter(allCats);
    setMasterSwitch(allCats.length);
  }, [allCats]);

  const showAll = () => {
    if (
      hideFilters.length > 0 &&
      filteredCategories.length > 0 &&
      catFilters.length === 0
    ) {
      setCatFilter(allCats);
      setHideFilter([]);
      setFilteredCategories([]);
      setMasterSwitch(masterSwitch - allCats.length);
    } else if (catFilters.length !== 0) {
      setHideFilter(allCats.slice());
      setFilteredCategories(allCats.slice());
      setCatFilter([]);
      setMasterSwitch(allCats.length);
    }
  };

  useEffect(() => {
    setMasterSwitch(filteredCategories.length);
  }, [filteredCategories]);

  const existsOnMap = (id: number) => {
    const included = categories.filter(s => s.id === id);
    if (included.length === 0) {
      const children = categories.filter(s => s.parentId === id);
      return children.length > 0 ? true : false;
    } else {
      return true;
    }
  };

  // This only filters them individually, needs another filter to filter main which includes all of the children aswell!
  const filterCategory = (clicked: Category) => {
    const newFilters = catFilters.slice();
    const oldFilters = hideFilters.slice();
    // Filter already active
    if (catFilters.includes(clicked)) {
      oldFilters.push(clicked);
      const index = catFilters.findIndex(
        (elem: Category): boolean => clicked.id === elem.id
      );
      newFilters.splice(index, 1);

      // Add filter to list
    } else {
      // Splice clicked from oldFilters
      const index2 = oldFilters.findIndex(
        (elem: Category): boolean => clicked.id === elem.id
      );
      oldFilters.splice(index2, 1);
      newFilters.push(clicked);
    }

    setFilteredCategories(oldFilters);
    setHideFilter(oldFilters);
    setCatFilter(newFilters);
  };
  // Filters children aswell
  const filterParent = (clicked: Category) => {
    const activelyFiltered = catFilters.filter(c => c.parentId === clicked.id);
    const catsOwnChildren = categories.filter(c => c.parentId === clicked.id);
    const newFilters = catFilters.slice();
    const oldFilters = hideFilters.slice();
    // Filter already active
    if (catFilters.includes(clicked)) {
      oldFilters.push(clicked);
      //splice parent
      const index = catFilters.findIndex(
        (elem: Category): boolean => clicked.id === elem.id
      );
      newFilters.splice(index, 1);

      // splice all children
      const children = allCats.filter(
        (elem: Category): boolean => clicked.id === elem.parentId
      );

      oldFilters.push(...children);

      children.forEach((c: Category) => {
        const index = newFilters.findIndex(
          (elem: Category): boolean => c.id === elem.id
        );
        newFilters.splice(index, 1);
      });

      // Add filter to list
    } else if (clicked.subCategories !== null) {
      if (activelyFiltered.length === catsOwnChildren.length) {
        // splice children
        const children = allCats.filter(
          (elem: any): any => elem.parentId === clicked.id
        );
        children.forEach((c: Category) => {
          const index = newFilters.findIndex(
            (elem: Category): boolean => c.id === elem.id
          );
          newFilters.splice(index, 1);
        });
        oldFilters.push(...children);
      }
      //splice parent
      const index2 = oldFilters.findIndex(
        (elem: Category): boolean => clicked.id === elem.id
      );
      oldFilters.splice(index2, 1);
      // splice all children
      const children2 = allCats.filter(
        (elem: Category): boolean => clicked.id === elem.parentId
      );
      children2.forEach((c: Category) => {
        const index = oldFilters.findIndex(
          (elem: Category): boolean => c.id === elem.id
        );
        oldFilters.splice(index, 1);
      });

      newFilters.push(clicked);
      // push all children with same parentId as clicked.Id
      const children = allCats.filter(
        (elem: any): any => elem.parentId === clicked.id
      );
      newFilters.push(...children);
    } else {
      //Only splice parent
      const index3 = oldFilters.findIndex(
        (elem: Category): boolean => clicked.id === elem.id
      );
      oldFilters.splice(index3, 1);
      newFilters.push(clicked);
    }

    setFilteredCategories(oldFilters);
    setHideFilter(oldFilters);
    setCatFilter(newFilters);
  };

  type ClickProps = {
    d: SubzoneType;
    map: MapRefType | null;
  };

  const subzoneClick = ({ d, map }: ClickProps) => {
    if (map && map.current) {
      const mapElem = map.current.leafletElement;
      let layerId = null;
      const layers: L.Layer[] = [];
      mapElem.eachLayer((l: L.Layer) => {
        layers.push(l);
      });
      layerId = Object.keys(layers).find(
        (key: string) => layers[key].options.id === `mapobject-${d.id}`
      );

      if (typeof layerId === 'undefined' || layerId === null) {
        const feat = centroid(d.geo).geometry;

        if (feat) {
          const coords: [number, number] = [
            feat.coordinates[1],
            feat.coordinates[0]
          ];

          const currentZoom = mapElem.getZoom();
          mapElem.setView(coords, currentZoom <= 18 ? 18 : currentZoom);

          layerId = Object.keys(layers).find(
            (key: string) => layers[key].options.id === `mapobject-${d.id}`
          );
        }
      }

      if (layerId !== undefined && layerId !== null) {
        const layer = layers[layerId];
        layer.fireEvent('click');

        if (layer._bounds) {
          mapElem.fitBounds(layer._bounds);
        }
      }
    }
  };

  return !selectedSubzone ? (
    // Front page
    <>
      {subzones.map((d: SubzoneType) => (
        <span
          className="category subzone"
          key={d.id}
          onClick={() => subzoneClick({ d, map })}
        >
          {d.title}
        </span>
      ))}
    </>
  ) : (
    // Subzone page
    <>
      {allCats.map(
        (d: Category) =>
          d.parentId === null &&
          existsOnMap(d.id) && (
            <CategoryItem
              category={d}
              key={d.id}
              catFilters={catFilters}
              setCatFilter={setCatFilter}
              onClickSub={filterCategory}
              onClickMain={filterParent}
            />
          )
      )}
      <div
        onClick={() => showAll()}
        style={{ borderTop: `1px solid ${colours.support}` }}
        className="category-master-switch"
      >
        <div
          style={
            masterSwitch === allCats.length
              ? { backgroundColor: colours.support }
              : { border: `1px solid ${colours.support}` }
          }
          className={`circle-checkbox master-icon`}
        >
          {masterSwitch === allCats.length ? (
            <Zicon
              customClass="circle-icon "
              color={`#f6f6f6`}
              icon="ui/checkmark"
            />
          ) : null}
        </div>
        <div className={`category btn filter `}>{`Show all`}</div>
      </div>
    </>
  );
};

export default Content;

import React from 'react';

import './tags.scss';

type TagProps = {
  id: number;
  title: string;
};

const Tags = (props: TagProps) => {
  return (
    <div className="tags-styled">
      <div className="tags-styled__tag">
        <span className="tags-styled__text">{props.title}</span>
      </div>
    </div>
  );
};

export default Tags;

import React, { useCallback, useContext, useEffect, useState } from 'react';

const TranslationContext = React.createContext({
  translate: (text: string): string => text,
  currentLanguage: ''
});

const FALLBACK_LANGUAGE = 'fi';
const translations = {
  fi: {
    'subzonepopup-link-text': 'Katso reittiohjeet',
    'subzonepopup-button-text': 'Tutustu alueeseen',
    'infobox-conservation-area': 'Luonnonsuojelualue',
    'forest-fire-card-title': 'Alueella on voimassa metsäpalovaroitus',
    'forest-fire-card-description':
      'Huomioithan retkeillessäsi alueella, että avotulen teko on kielletty kuivan ympäristön vuoksi.\n\r\n\rVoit kuitenkin käyttää alueelta löytyviä erikseen merkittyjä nuotiopaikkoja, vaikka varoitus olisi voimassa.',
    'forest-fire-card-link-url': 'https://www.ilmatieteenlaitos.fi/',
    'forest-fire-card-link-text': 'Ilmatieteenlaitos.fi',
    'current-location-success': 'Nykyisen sijainnin määrittäminen onnistui',
    'current-location-error': 'Nykyisen sijainnin määrittäminen epäonnistui',
    'current-location-outside': 'Nykyinen sijainti alueen ulkopuolella',
    attachment: 'Liitteet:',
    'overlay-frontpage': 'Tutustu alueisiin',
    'overlay-subzone': 'Tutustu alueeseen',
    'filter': 'Suodata'
  },
  en: {
    'subzonepopup-link-text': 'Show area',
    'subzonepopup-button-text': 'Show area',
    'infobox-conservation-area': 'Nature conservation area',
    'forest-fire-card-title': 'Alueella on voimassa metsäpalovaroitus',
    'forest-fire-card-description':
      'Huomioithan retkeillessäsi alueella, että avotulen teko on kielletty kuivan ympäristön vuoksi.\n\r\n\rVoit kuitenkin käyttää alueelta löytyviä erikseen merkittyjä nuotiopaikkoja, vaikka varoitus olisi voimassa.',
    'forest-fire-card-link-url': 'https://en.ilmatieteenlaitos.fi/',
    'forest-fire-card-link-text': 'Finnish Meteorological Institute',
    'current-location-success': 'Current location found successfully',
    'current-location-error': 'Error accessing current location',
    'current-location-outside': 'Current location outside area',
    attachment: 'Attachments:',
    'overlay-frontpage': 'Tutustu alueisiin',
    'overlay-subzone': 'Tutustu alueeseen',
    'filter': 'Filter'
  },
  sv: {
    'subzonepopup-link-text': 'Visa område',
    'subzonepopup-button-text': 'Visa område',
    'infobox-conservation-area': 'Luonnonsuojelualue',
    'forest-fire-card-title': 'Alueella on voimassa metsäpalovaroitus',
    'forest-fire-card-description':
      'Huomioithan retkeillessäsi alueella että avotulen teko on kielletty kuivan ympäristön vuoksi.\n\r\n\rVoit kuitenkin käyttää alueelta löytyviä erikseen merkittyjä nuotiopaikkoja vaikka varoitus olisi voimassa.',
    'forest-fire-card-link-url': 'https://sv.ilmatieteenlaitos.fi/',
    'forest-fire-card-link-text': 'Meteorologiska institutet',
    'current-location-success': 'Nykyisen sijainnin määrittäminen onnistui',
    'current-location-error': 'Nykyisen sijainnin määrittäminen epäonnistui',
    'current-location-outside': 'Nykyinen sijainti alueen ulkopuolella',
    attachment: 'Liitteet:',
    'overlay-frontpage': 'Tutustu alueisiin',
    'overlay-subzone': 'Tutustu alueeseen',
    'filter': 'Suodata'
  }
};

type QueryParamsProps = {
  lang?: string;
};

const getQueryParams = (): QueryParamsProps =>
  window.location.search
    .replace('?', '')
    .split('&')
    .reduce(
      // eslint-disable-next-line
      (r, e) => ((r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])), r),
      {}
    );

const TranslationProvider = ({ children }: any): JSX.Element => {
  const [language, setLanguage] = useState<string>('');
  const translate = useCallback(
    (text: string) => translations[language || FALLBACK_LANGUAGE][text] || text,
    [language]
  );

  useEffect(() => {
    const params = getQueryParams();
    if (params?.lang && translations[params?.lang.toLowerCase().trim()]) {
      setLanguage(params?.lang.toLowerCase().trim());
    }
  }, [setLanguage]);

  return (
    <TranslationContext.Provider
      value={{
        translate,
        currentLanguage: language
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

const useTranslation = () => useContext(TranslationContext);

export { TranslationProvider, TranslationContext, useTranslation };
export default TranslationContext;

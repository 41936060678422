/* eslint-disable no-restricted-globals */
import React from 'react';
import { Polyline } from 'react-leaflet';
import { LatLngBounds } from 'leaflet';
import { useCtx } from '../../Context';
import { MapItem, MapRefType, Category } from '../../../types';
import './Routes.scss';
import '../Markers/Markers.scss';
import { isMobile } from '../../../utils';

type clickProps = {
  selectMarker: (route: MapItem) => void;
  item: MapItem;
  map: MapRefType | null;
  bounds: LatLngBounds;
};

const lineLayout = (
  category: string,
  selected: boolean,
  strokeWeight: number,
  dashArray: string,
  color: string
): object => {
  const layout = {
    fill: false,
    stroke: true,
    weight: strokeWeight,
    dashArray,
    color: `${color !== '' ? color : '#00B4A9'}`,
    opacity: 1,
    noClip: true,
    className: `route line ${selected ? 'selected' : ''}  route--${category}`
  };
  if (selected) {
    layout.weight = 5;
    layout.dashArray = '0';
  }

  return layout;
};

const handleClick = ({ selectMarker, item, map, bounds }: clickProps): void => {
  selectMarker(item);
  if (map && map.current) {
    const mapElem = map.current.leafletElement;

    let padding: [number, number] = [0, 0];
    if (isMobile) {
      padding = [0, window.innerHeight * 0.6];
    }

    mapElem.fitBounds(bounds, { maxZoom: 18, paddingBottomRight: padding });
  }
};

const Routes = (): JSX.Element => {
  const strokeWeight = 3;
  const {
    routes,
    selectMarker,
    selectedMarker,
    map,
    filteredCategories,
    selectedSubzone,
    zoomLevel
  } = useCtx();
  return (
    <>
      {routes.map((r: MapItem, i: number) => {
        let selected = false;

        let cat = '';
        let catId: number | null = null;
        if (r.Categories.length !== 0) {
          cat = r.Categories[0].slug;
          catId = r.Categories[0].id;
        }

        let dashArray = '0';
        if (r.styleProperties?.lineStyle === 'dotted') {
          dashArray = '1, 10';
        } else if (r.styleProperties?.lineStyle === 'dashed') {
          dashArray = '10, 10';
        }

        let color = '';
        const category = filteredCategories.find(
          (c: Category) => c.id === catId
        );
        if (category) {
          color = category.color;
        } else if (!category && r.Categories.length !== 0) {
          return null;
        }

        if (selectedMarker) {
          selected = selectedMarker.id === r.id;
        }

        const layout = lineLayout(
          cat,
          selected,
          strokeWeight,
          dashArray,
          color
        );
        const layout2 = {
          fill: false,
          stroke: true,
          weight: selected ? 15 : 20,
          dashArray,
          color: color,
          opacity: selected ? 0.3 : 0,
          noClip: true,
          className: `route-line line`
        };

        return (
          <div key={i}>
            <Polyline
              key={i}
              positions={r.geo.coordinates as any}
              interactive={r.isInteractive}
              onClick={(e: any): void => {
                if (selectedSubzone) {
                  handleClick({
                    selectMarker,
                    item: r,
                    map,
                    bounds: e.target.getBounds()
                  });
                  history.pushState(
                    null,
                    '',
                    `/${selectedSubzone.slug}/${r.slug}/#/${zoomLevel}/${
                      e.target.getBounds().getCenter().lat
                    }/${e.target.getBounds().getCenter().lng}`
                  );
                }
              }}
              {...layout2}
            />
            {/* Another polyline (not visible) for better clickability of the route */}
            <Polyline
              key={`line2-${i}`}
              positions={r.geo.coordinates as any}
              interactive={r.isInteractive}
              onClick={(e: any): void => {
                if (selectedSubzone) {
                  handleClick({
                    selectMarker,
                    item: r,
                    map,
                    bounds: e.target.getBounds()
                  });
                  history.pushState(
                    null,
                    '',
                    `/${selectedSubzone.slug}/${r.slug}/#/${zoomLevel}/${
                      e.target.getBounds().getCenter().lat
                    }/${e.target.getBounds().getCenter().lng}`
                  );
                }
              }}
              {...layout}
            />
          </div>
        );
      })}
    </>
  );
};

export default Routes;

import { centroid } from '@turf/turf';

export const getPolygonCenter = (geojson: any) => {
  const center = centroid(geojson);
  return center.geometry ? center.geometry.coordinates : [0, 0];
};

export const isMobile = window.innerWidth <= 900;

export const slugify = (text: string): string =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

export const isEmbed = (): boolean => {
  const urlParams =
    typeof URLSearchParams !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : false;
  let embed = urlParams && urlParams.get('embed') !== null;
  // Check is iframe
  if (embed) {
    try {
      embed = window.self !== window.top;
    } catch (e) {
      embed = true;
    }
  }

  return embed;
};

export const validateMaptile = (mapstyle: string): boolean => {
  const regex = new RegExp('https://api.maptiler.com/');
  if (regex.test(mapstyle)) {
    return true;
  }
  return false;
};

/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import { Map, ZoomControl, Pane, ScaleControl } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import { bbox, centroid, feature, featureCollection } from '@turf/turf';
import { useRouteMatch } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'mapbox-gl-leaflet';
import './map.scss';

import UserLocateButton from './UserLocateButton';
import Markers from './Markers';
import Routes from './Routes';
import Areas from './Areas';
import Subzones from './Subzones';
import Message from '../Message';
import { useCtx } from '../Context';
import { SubzoneType, MapItem, /* Coordinate, */ Category } from '../../types';
import { isMobile, validateMaptile } from '../../utils';
import FullScreenButton from './FullScreenButton';

const defaultLatLng: LatLngTuple = [60.216685, 24.869079];
const defaultZoom = 15;
const DEFAULT_STYLE = `${process.env.REACT_APP_DEFAULT_STYLE}`;

L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

const isDesktopSafari = (): boolean => {
  const uA = navigator.userAgent;
  const vendor = navigator.vendor;
  return (
    /Safari/i.test(uA) &&
    /Apple Computer/.test(vendor) &&
    !/Mobi|Android/i.test(uA)
  );
};

const setTileLayer = (map: Map, mapstyle: any): void => {
  if (map && validateMaptile(mapstyle)) {
    L.mapboxGL({
      accessToken: '...',
      style: mapstyle
    }).addTo(map.leafletElement);
  } else {
    L.mapboxGL({
      accessToken: '...',
      style: DEFAULT_STYLE
    }).addTo(map.leafletElement);
  }
};
// Closes menu on map event 'click' if user is in mobile view and menu is open ofc.
const ListenForEvents = (
  map: Map,
  setMenuOpen: React.Dispatch<any>,
  setCenter: React.Dispatch<any>,
  setZoomLevel: React.Dispatch<any>,
  setFullScreen?: React.Dispatch<any>
): void => {
  map.leafletElement.on('click', () => {
    if (isMobile) {
      setMenuOpen(false);
    }
  });
  map.leafletElement.on('fullscreenchange', e => {
    if (e.target._isFullscreen && setFullScreen) {
      setFullScreen(1);
    } else if (e.target._isFullscreen === false && setFullScreen) {
      setFullScreen(0);
    }
  });
  map.leafletElement.on('moveend', e => {
    if (e.target) {
      setCenter([e.target.getCenter().lat, e.target.getCenter().lng]);
      setZoomLevel(e.target.getZoom());
    }
  });
};

const Watermark = (): JSX.Element => (
  <a
    href="https://www.zoneatlas.com"
    target="_blank"
    rel="noopener noreferrer"
    className="watermark"
  >
    ZONEATLAS <span className="hidden-mobile">interactive maps</span>
  </a>
);

const generateClusterIconData = (
  // MarkerCluster here, but couldn't get to work
  cluster: any,
  prioCategories: string[]
): { slug: string | null; childCount: number } => {
  let slug: string | null = null;
  let iconIndex = prioCategories.length;
  const markers = cluster.getAllChildMarkers();

  // Should put here react-leaflet Marker, but is atm leaflet Marker
  markers.forEach((m: any) => {
    if (slug !== prioCategories[0]) {
      for (let i = 0; i < iconIndex; i++) {
        if (m.options.children) {
          const category = m.options.children.props.className;
          if (
            category.indexOf(prioCategories[i]) !== -1 &&
            (!slug || (slug && iconIndex > i))
          ) {
            slug = prioCategories[i];
            iconIndex = i;
            break;
          }
        }
      }
    }

    // If no prioritised categories in the cluster just pick the first
    if (slug === null && markers[0].options.children) {
      slug = markers[0].options.children.props.className;
    }
  });

  let childCount = cluster.getChildCount();
  return {
    slug,
    childCount
  };
};

const LeafletMap = (props: any): JSX.Element => {
  const {
    settings,
    setMap,
    subzones,
    selectSubzone,
    selectedSubzone,
    markers,
    selectMarker,
    selectedMarker,
    categories,
    embed,
    colours,
    allCats,
    setMenuOpen,
    setCenter,
    setZoomLevel
    /* center,
    zoomLevel */
  } = useCtx();
  const mapRef = useRef<Map>(null);
  const zoomRef = useRef<any>(null);
  const [classes, setClasses] = useState('');
  /*   const [fullScreen, setFullScreen] = useState<number>(0); */
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const match = useRouteMatch();
  // Inside the component to access categories from context
  const markerClusterIconCreate = (cluster: any): L.DivIcon => {
    const prioCategories = [
      'nuotiopaikat',
      'tulentekopaikka',
      'infotaulu',
      'taukopaikka',
      'kuivakaymala'
    ];

    const { childCount, slug } = generateClusterIconData(
      cluster,
      prioCategories
    );

    let text: any = slug === 'subzone-popup' ? childCount : `+${childCount}`;
    let spanClass = 'count';
    const icon = allCats.find((m: Category) => m.icon === slug);
    if (childCount && childCount < 10) {
      text = slug === 'subzone-popup' ? childCount : `${childCount}`;
      spanClass = 'count';
    } else {
      text =
        slug === 'subzone-popup'
          ? childCount
          : `+${(childCount / 10).toFixed(0)}0`;
      spanClass = 'count-big';
    }
    const icon2 = ReactDOMServer.renderToString(
      <>
        <span
          className={`${spanClass} ${
            slug === 'subzone-popup' ? 'subzone' : ''
          }`}
        >
          {text}
        </span>
      </>
    );

    return L.divIcon({
      html: `${icon2}`,
      className: `marker-cluster-${cluster._leaflet_id} ${icon} marker-cluster`,
      iconSize: L.point(50, 50, true)
    });
  };

  const markerClusterProps = {
    spiderfyOnMaxZoom: false,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    removeOutsideVisibleBounds: false,
    disableClusteringAtZoom: 15,
    maxClusterRadius: 40,
    chunkedLoading: true,
    iconCreateFunction: markerClusterIconCreate
  };

  useEffect(() => {
    // Set selected subzone to the current subzone
    if (match.path !== '/') {
      const selected = subzones.find(
        (s: SubzoneType) => s.slug === match.params['subzone']
      );
      selectSubzone(selected || null);
    }
  }, [match.path, match.params, selectSubzone, subzones]);

  useEffect(() => {
    // Set map ref, so other components can access it from the context
    if (mapRef && mapRef.current) {
      setMap(mapRef);
      setTileLayer(mapRef.current, settings.defaultMapTileUrl);
      ListenForEvents(mapRef.current, setMenuOpen, setCenter, setZoomLevel);
    }
  }, [mapRef, setMap, settings.defaultMapTileUrl]);

  useEffect(() => {
    if (markers.length !== 0) {
      const mapitem = match.params['mapitem'];
      if (mapitem) {
        const selected = markers.find((m: MapItem) => m.slug === mapitem);
        selectMarker(selected || null);
      }
    }
  }, [match.params, selectMarker, markers]);

  // If only current path is at index ( '/' ) and no subzone is selected while only theres 1 subzone, select it
  useEffect(() => {
    if (match.path === '/' && subzones.length === 1) {
      selectSubzone(subzones[0]);
    }
  }, [match.path, selectSubzone, subzones]);

  useEffect(() => {
    // Fit marker bounds if subzone selected
    if (
      selectedSubzone &&
      mapRef.current &&
      Object.keys(match.params).length === 1
    ) {
      const features = markers.map((m: MapItem) => feature(m.geo));
      if (!features.length) {
        return;
      }

      if (features.length > 1) {
        const bounds = bbox(featureCollection(features));
        const currentZoom = mapRef.current.leafletElement.getCenter();
        const bounds2 = L.latLngBounds(
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]]
        );
        // Don't fly to bounds if we are already inside the bounds
        if (!bounds2.contains(currentZoom)) {
          mapRef.current.leafletElement.flyToBounds(bounds2, {
            duration: 0.05
          });
        }
      }
    } else if (
      // Else if subzone not selected fit subzones
      !selectedSubzone &&
      !markers.length &&
      mapRef.current &&
      subzones.length &&
      Object.keys(match.params).length === 0
    ) {
      if (subzones.length > 1) {
        const features = subzones.map((m: SubzoneType) => centroid(m.geo));
        const bounds = bbox(featureCollection(features));
        const latlangbox = [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]]
        ];
        mapRef.current.leafletElement.fitBounds(
          latlangbox as L.LatLngBoundsExpression,
          { padding: [20, 20] }
        );
      } else {
        const nums = centroid(subzones[0].geo).geometry;
        if (nums !== null && history.state !== null) {
          const coords = [nums.coordinates[1], nums.coordinates[0]];
          mapRef.current.leafletElement.flyTo(coords as L.LatLngExpression);
        }
      }
    }
  }, [selectedSubzone, markers, subzones, match.params]);

  useEffect(() => {
    if (selectedMarker) {
      setClasses('up');
    }
  }, [selectedMarker]);

  // Hide message after 4s
  useEffect(() => {
    if (message !== null) {
      const timer = setTimeout(() => setMessage(null), 4000);
      return (): void => clearTimeout(timer);
    }
    return;
  }, [message]);

  useEffect(() => {
    let elem: any = document.getElementsByClassName(
      'leaflet-control-fullscreen-button'
    )[0];
    if (elem) {
      elem.style.backgroundColor = colours.bg;
      elem.style.color = colours.text;
    }
  }, [colours]);

  useEffect(() => {
    if (window.location.hash && mapRef.current) {
      console.log(
        [
          +window.location.hash.split('/')[2],
          +window.location.hash.split('/')[3]
        ],
        +window.location.hash.split('/')[1]
      );
      mapRef.current.leafletElement.flyTo(
        [
          +window.location.hash.split('/')[2],
          +window.location.hash.split('/')[3]
        ],
        +window.location.hash.split('/')[1]
      );
    }
  }, [mapRef]);

  return (
    <Map
      id="mapId"
      ref={mapRef}
      center={settings.defaultMapCenter?.coordinates || defaultLatLng}
      zoom={settings.defaultMapZoomLevel || defaultZoom}
      maxZoom={19}
      tap={false}
      zoomControl={false}
      className={classes}
      scrollWheelZoom={!isMobile ? !embed : true}
      style={{ backgroundColor: colours.bg }}
      whenReady={(): void => {
        if (embed && !isDesktopSafari()) {
          (mapRef.current?.leafletElement as any).gestureHandling.enable();
        }
      }}
    >
      <FullScreenButton handle={props.handle} />
      <Watermark />
      <ScaleControl imperial={false} />
      {/* Edit message with message parameter */}
      <Message message={message} isError={error} />
      <ZoomControl ref={zoomRef} id="zoomControl" position="bottomright" />
      <UserLocateButton
        setError={setError}
        mapRef={mapRef}
        selectedSubzone={selectedSubzone}
        setMessage={setMessage}
      />
      {!selectedSubzone ? (
        <MarkerClusterGroup {...markerClusterProps}>
          <Subzones />
        </MarkerClusterGroup>
      ) : (
        /* These are shown only on subzone pages */
        <>
          {/* <button className="back-btn" onClick={() => {document.location.href = '/'}}><Zicon icon="ui/arrow/left" color="#2800ff" /></button */}
          {categories.length !== 0 ? (
            <MarkerClusterGroup key="withCategories" {...markerClusterProps}>
              <Markers />
            </MarkerClusterGroup>
          ) : (
            <MarkerClusterGroup key="withoutCategories" {...markerClusterProps}>
              <Markers />
            </MarkerClusterGroup>
          )}
          <Pane name="route" className="route-pane">
            <Routes />
          </Pane>
          <Pane name="area" className="area-pane">
            <Areas />
          </Pane>
        </>
      )}
    </Map>
  );
};

export default LeafletMap;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
  MapItem,
  SubzoneType,
  Category,
  ItemCategory,
  MapRefType,
  ContextType,
  TagType,
  ColourType
} from '../../types';
import { useTranslation } from './Translations';
import { isEmbed } from '../../utils';

// Context wrapper to avoid initial undefined context type
const createCtx = (): readonly [
  () => ContextType,
  React.Provider<ContextType | undefined>
] => {
  const ctx = React.createContext<ContextType | undefined>(undefined);
  const useCtx = (): ContextType => {
    const c = React.useContext(ctx);
    if (!c) throw new Error('useCtx must be inside a Provider with a value');
    return c;
  };
  return [useCtx, ctx.Provider] as const;
};

// const WEATHER_WARNINGS_REGION_CODE = '034';

const sameDate = (f: Date, s: Date): boolean =>
  f.getFullYear() === s.getFullYear() &&
  f.getMonth() === s.getMonth() &&
  f.getDate() === s.getDate();

const localStorage = {
  get: (item: string): any => {
    const data = window.localStorage.getItem(item);
    return data ? JSON.parse(data) : null;
  },
  set: (item: string, value: any): void =>
    window.localStorage.setItem(item, JSON.stringify(value)),
  remove: (item: string): void => window.localStorage.removeItem(item),
  clear: (): void => window.localStorage.clear()
};

const [useCtx, CtxProvider] = createCtx();

const AppContextProvider = ({ children }: any): JSX.Element | null => {
  const [loading, setLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState<any[]>([]);
  const [colours, setColours] = useState<ColourType>({ bg: 'fafafa' });
  const [categories, setCategories] = useState<Category[]>([]);
  const [center, setCenter] = useState<any[]>([]);
  const [zoomLevel, setZoomLevel] = useState<number>(15);
  const [allCats, setAllCats] = useState<Category[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [markers, setMarkers] = useState<MapItem[]>([]);
  const [routes, setRoutes] = useState<MapItem[]>([]);
  const [areas, setAreas] = useState<MapItem[]>([]);
  const [mapItems, setMapItems] = useState<MapItem[]>([]);
  const [selectedMarker, selectMarker] = useState<MapItem | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [map, setMap] = useState<MapRefType | null>(null);
  const [subzones, setSubzones] = useState<SubzoneType[]>([]);
  const [filteredSubzones, setFilteredSubzones] = useState<SubzoneType[]>([]);
  const [selectedSubzone, selectSubzone] = useState<SubzoneType | null>(null);
  const [areaTags, setAreaTags] = useState<TagType[]>([]);
  const [activityTags, setActivityTags] = useState<TagType[]>([]);
  const [weatherWarnings, setWeatherWarnings] = useState<Array<any> | null>(
    null
  );
  const [embed, setEmbed] = useState<boolean>(false);

  const { currentLanguage } = useTranslation();

  const parseWeatherWarnings = (data: any): void =>
    // @TODO: uncomment forest fire filtering when publishing to production. Now showing warning if there's any warnings.
    setWeatherWarnings(
      // data.filter(
      //   ({ code, today }: any) =>
      //     +WEATHER_WARNINGS_REGION_CODE === +code &&
      //     today.find(
      //       ({ awareness }: { awareness: any }) =>
      //         awareness.type === 'FOREST_FIRE' // parse only forest fires
      //     )
      // )
      data
    );

  const getWeatherWarnings = React.useCallback(() => {
    const stored = localStorage.get('zoneatlas-weather-warnings');
    if (stored && stored.data && sameDate(new Date(stored.ts), new Date())) {
      parseWeatherWarnings(stored.data);
    } else {
      const apiUrl = `${process.env.REACT_APP_API_URL}/weather-warnings`;
      axios
        .get(apiUrl, {
          params: {
            lang: currentLanguage
          }
        })
        .then(({ data }) => {
          parseWeatherWarnings(data);

          // Save all data to localstorage
          localStorage.set('zoneatlas-weather-warnings', {
            ts: new Date(),
            data
          });
        })
        .catch(err => console.log(err));
    }
  }, [currentLanguage]);

  useEffect(() => {
    setEmbed(isEmbed());
  }, []);

  // Get Zone settings and sub zones
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/settings`, {
        params: {
          lang: currentLanguage
        }
      })
      .then(res => {
        setSettings(res.data);
        setColours({
          bg: res.data.zoneBaseColor,
          text: res.data.zoneTextColor,
          support: res.data.zoneSupportColor
        });

        axios
          .get(`${process.env.REACT_APP_API_URL}/subzones`, {
            params: {
              lang: currentLanguage
            }
          })
          .then(res => {
            setSubzones(res.data);
            setFilteredSubzones(res.data);
            setLoading(false);
          });
      });
  }, [currentLanguage]);

  useEffect(() => {
    // Get mapitems for the subzone selected
    if (selectedSubzone) {
      const markers2: MapItem[] = [];
      const routes2: MapItem[] = [];
      const areas2: MapItem[] = [];

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/subzones/${selectedSubzone.id}/mapitems`,
          {
            params: {
              lang: currentLanguage
            }
          }
        )
        .then(res => {
          res.data.forEach((d: MapItem) => {
            if (d.type === 'Route') {
              routes2.push(d);
            } else if (d.type === 'Point') {
              markers2.push(d);
            } else if (d.type === 'Area') {
              areas2.push(d);
            }
          });
          setRoutes(routes2);
          setAreas(areas2);
          setMarkers(markers2);
          setMapItems(res.data);

          // Get weather warnings
          getWeatherWarnings();
        })
        .catch(e => console.log(e));
    }
  }, [selectedSubzone, getWeatherWarnings, currentLanguage]);

  useEffect(() => {
    // Categories are needed only in the subzone view
    if (selectedSubzone) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/categories`, {
          params: {
            lang: currentLanguage
          }
        })
        .then(res => {
          const subzoneCats = [];
          // Filter categories with categories of mapItems in the subzone
          for (let j = 0; j < mapItems.length; j++) {
            for (let k = 0; k < res.data.length; k++) {
              // Check that a mapItem has the category and that category is not yet appended
              if (
                mapItems[j].Categories.find(
                  (el: ItemCategory) => el.id === res.data[k].id
                ) !== undefined &&
                subzoneCats.find((el: Category) => el.id === res.data[k].id) ===
                  undefined
              ) {
                subzoneCats.push(res.data[k]);
              }
            }
          }

          res.data.sort((a: any, b: any) => {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          });

          const onlyActive: any = [];
          res.data.forEach((item: any) => {
            if (item.parentId !== null) {
              if (
                mapItems.map((m: any) => m.Categories[0].id).includes(item.id)
              ) {
                onlyActive.push(item);
              }
            } else if (item.parentId === null) {
              onlyActive.push(item);
            }
          });

          setCategories(subzoneCats);
          setAllCats(onlyActive);
        });
    }
  }, [selectedSubzone, mapItems, currentLanguage]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/tags`, {
        params: {
          lang: currentLanguage
        }
      })
      .then(res => {
        const tags = res.data;
        const arTags: TagType[] = [];
        const acTags: TagType[] = [];
        for (let i = 0; i < tags.length; i++) {
          const splitted = tags[i].title.split('_');
          if (splitted.length === 2) {
            if (splitted[0] === 'activity') {
              tags[i].title = splitted[1];
              //tags[i].order = activityTagOrder(slugify(tags[i].title));
              acTags.push(tags[i]);
            } else if (splitted[0] === 'area') {
              tags[i].title = splitted[1];
              arTags.push(tags[i]);
            }
          }
        }

        //const sorted = acTags.sort((a: any, b: any) => a.order - b.order);
        setAreaTags(arTags);
        setActivityTags(acTags);
      });
  }, [currentLanguage]);

  const defaultValue = {
    settings,
    markers,
    routes,
    areas,
    categories,
    allCats,
    filteredCategories,
    colours,
    setColours,
    setFilteredCategories,
    selectedMarker,
    selectMarker,
    map,
    setMap,
    subzones,
    selectedSubzone,
    selectSubzone,
    filteredSubzones,
    setFilteredSubzones,
    areaTags,
    activityTags,
    weatherWarnings,
    embed,
    menuOpen,
    setMenuOpen,
    zoomLevel,
    setZoomLevel,
    center,
    setCenter
  };

  if (loading) {
    return null;
  }

  return <CtxProvider value={defaultValue}>{children}</CtxProvider>;
};

export { useCtx, AppContextProvider };

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';

import 'leaflet/dist/leaflet.css';
import './App.scss';

import Map from './components/Map';
import Card from './components/Card';
import Menu from './components/Menu';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { AppContextProvider } from './components/Context';
import { TranslationProvider } from './components/Context/Translations';

axios.defaults.headers.common['x-container'] = process.env.REACT_APP_ZONE_ID;

function App(): JSX.Element {
  const handle = useFullScreenHandle();

  return (
    <div className="App">
      <TranslationProvider>
        <AppContextProvider>
          <FullScreen handle={handle}>
            <Router>
              <Card />
              <Menu />
              <Switch>
                <Route path="/:subzone/:mapitem">
                  <Map handle={handle} />
                </Route>
                <Route path="/:subzone">
                  <Map handle={handle} />
                </Route>
                <Route exact path="/">
                  <Map handle={handle} />
                </Route>
              </Switch>
            </Router>
          </FullScreen>
        </AppContextProvider>
      </TranslationProvider>
    </div>
  );
}

export default App;
